import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Audiobook } from "resources/AudiotekaApi";
import { Text } from "ui/text";

import { AbookIcon } from "../../abook-icon";
import css from "./rating-badge.module.scss";

interface Props {
  audiobook: Audiobook;
  className?: string;
}

export function RatingBadge({ audiobook, className }: Props) {
  const t = useTranslations("product.reviews");
  const count = audiobook.rating_count;
  const average = Math.round(audiobook.rating * 10) / 10;

  return (
    <div className={classNames(css.ratingBadge, className)}>
      <div className={css.badgeWrapper}>
        <AbookIcon icon="badge" width="24" height="24" />
        <div className={css.badgeContent}>
          {count > 0 ? average : <AbookIcon className={css.noRatingIcon} icon="rate" width="24" height="24" />}
        </div>
      </div>
      <Text type="bodyBig" m="0">
        {count > 0 ? t("average", { count }) : t("no_rates")}
      </Text>
    </div>
  );
}

import React from "react";
import { useTranslations } from "next-intl";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";
import { Text } from "ui/text";
import { useUser } from "store/useUser";

import { getIsOnShelf, getPrices } from "../../../utils";
import { InfoIcon } from "../../info-icon/info-icon";
import css from "./description.module.scss";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
};

export function Description({ audiobook, licenses }: Props) {
  const t = useTranslations();
  const user = useUser((state) => state.data);

  if (audiobook.is_free) {
    return (
      <Text type="bodySmall" className={css.description}>
        {t("product.price_box.title.free")}{" "}
        {!user && <InfoIcon className={css.infoIcon} info={t("product.price_box.info.free")} iconSize={18} />}
      </Text>
    );
  }

  const isOnShelf = getIsOnShelf(licenses);

  if (isOnShelf) {
    return (
      <Text type="bodySmall" className={css.description}>
        <NewIcon icon="shelf" width="20" height="20" />
        {t("product.price_box.title.shelf")}
      </Text>
    );
  }

  const clubListen = audiobook.is_available_in_subscription;

  if (clubListen) {
    return (
      <Text type="bodySmall" className={css.description}>
        <NewIcon className={css.shelfIcon} icon="logo" width="20" height="20" />
        {t("product.price_box.subtitle.club_available")}
      </Text>
    );
  }

  const isPremium = audiobook._embedded["app:context"].is_premium;
  if (isPremium) return null;

  const price = getPrices(audiobook, licenses, user?.isClubMember).withCurrency;
  const canBuyInClub = !!price.base && !!price.club;

  if (
    (canBuyInClub && user?.isClubMember && price.club === price.final) ||
    (canBuyInClub && !user?.isClubMember && price.club < price.final)
  ) {
    return (
      <Text type="bodySmall" className={css.description}>
        <NewIcon className={css.shelfIcon} icon="logo-alt" width="20" height="20" />
        {t("product.price_box.info.club_special_price")}
      </Text>
    );
  }

  return null;
}

import React from "react";
import { useTranslations } from "next-intl";

import { Text } from "ui/text";
import { NewIcon } from "ui/NewIcon";

import css from "./common.module.scss";

function Argumenter({ label }: { label: string }) {
  return (
    <Text type="bodySmall" as="li" className={css.argumenter}>
      <NewIcon icon="check-argumenter" width="14" height="14" />
      <span className={css.label}>{label}</span>
    </Text>
  );
}

const argumenterNumbers = [1, 2, 3, 4] as const;

export function ArgumenterList({ isClubListenAvailable }: { isClubListenAvailable: boolean }) {
  const t = useTranslations();

  const labels = argumenterNumbers.map((number) =>
    t(`product.price_box.argumenters.club_listen_${isClubListenAvailable ? "" : "un"}available.argumenter${number}`)
  );

  const hasAnyLabel = labels.some((label) => label.trim() !== "");

  if (!hasAnyLabel) return null;

  return (
    <ul className={css.argumenterList}>
      {labels.map((label) => {
        if (!label) return null;

        return <Argumenter key={label} label={label} />;
      })}
    </ul>
  );
}

import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Text } from "ui/text";

import css from "./not-available.module.scss";

export const NotAvailable = ({ className }: { className?: string }) => {
  const t = useTranslations();

  return (
    <div className={classNames(css.notAvailable, className)}>
      <Text type="subtitleBig" className={css.paragraph}>
        {t("product.not_available")}
      </Text>
    </div>
  );
};

import React from "react";
import dynamic from "next/dynamic";

import SlideBar from "modules/DesignSystem/components/SlideBar";
import { Audiobook, ProductReview } from "resources/AudiotekaApi";

import { Loader } from "../loader/loader";
import { Header } from "./header";

const Content = dynamic(() => import("./content/content"), {
  ssr: false,
  loading: () => <Loader />,
});

interface Props {
  canRate: boolean;
  open: boolean;
  audiobook: Audiobook;
  review: ProductReview | null;
  onRate: () => void;
  onClose: () => void;
}

export function ReviewsSlideBar({ canRate, open, audiobook, review, onClose, onRate }: Props) {
  return (
    <SlideBar header={<Header canRate={canRate} audiobook={audiobook} onRate={onRate} />} onClose={onClose} open={open}>
      {open && <Content audiobook={audiobook} review={review} onRate={onRate} />}
    </SlideBar>
  );
}

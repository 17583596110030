import React, { useCallback, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";

import { useUser } from "store/useUser";
import Grid from "components/Grid";
import { HtmlToReact } from "components/html-to-react";
import { Audiobook as AudiobookType, getLicensesChannels, LicenseChannels } from "resources/AudiotekaApi";
import { getCanListen } from "utils/getCanListen";

import { Alert } from "./components/alert";
import { ReviewsBox } from "./components/reviews-box";
import { ClubMemberMobile } from "./components/price-box/boxes/club/club-member-mobile";
import { NotAvailable, PriceBox } from "./components/price-box";
import { PriceBoxDesktop } from "./components/PriceBoxDesktop";
import { ProductTop } from "./components/product-top";
import Description from "./components/description/description";
import { HowToListen } from "./components/how-to-listen/how-to-listen";
import { useAnalytics } from "./useAnalytics";
import { getIsOnShelf } from "./utils";
import css from "./audiobook.module.scss";

const AttachmentList = dynamic(() => import("./components/attachment-list"), { ssr: false });

interface Props {
  audiobook: AudiobookType;
  darkMode?: boolean;
  linkReplace: string | null;
  currency: string;
}

const getCanRate = (licenses: LicenseChannels | undefined): boolean => {
  if (!licenses) {
    return false;
  }

  const licenseStates = Object.values(licenses).map((license) => license.state);
  return getCanListen(licenses) || licenseStates.includes("NOT_AVAILABLE_ON_THIS_DEVICE");
};

export const Audiobook = ({ audiobook, darkMode, linkReplace, currency }: Props) => {
  const reviewsRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const user = useUser((state) => state.data);
  const isUserFetched = useUser((state) => state.status === "fetched");

  const [licenses, setLicenses] = useState<LicenseChannels | undefined>(undefined);

  useAnalytics(audiobook, currency);

  const canListen = getCanListen(licenses);
  const canRate = getCanRate(licenses);

  const isAvailable =
    canListen ||
    (audiobook._embedded["app:context"].is_enabled &&
      (!!audiobook.price ||
        !!audiobook.price_for_subscribers ||
        !!audiobook.is_available_in_subscription ||
        audiobook.is_free));

  useEffect(() => {
    if (linkReplace && window.location.pathname !== linkReplace) {
      window.history.replaceState(window.history.state, window.document.title, linkReplace);
    }
  }, [linkReplace]);

  useEffect(() => {
    if (!isUserFetched) {
      return;
    }

    async function loadLicensesChannels() {
      try {
        const { data: licensesChannels } = await getLicensesChannels(audiobook.id);
        setLicenses(licensesChannels);
      } catch {
        setLicenses(undefined);
      }
    }

    if (user) {
      loadLicensesChannels();
    } else {
      setLicenses(undefined);
    }
  }, [isUserFetched, user?.id, audiobook]);

  const scrollToReviews = useCallback(() => {
    if (reviewsRef.current) {
      window.scrollBy({
        behavior: "smooth",
        top: reviewsRef.current.getBoundingClientRect().top - 70,
      });
    }
  }, [reviewsRef.current]);

  const priceBox = isAvailable ? (
    <PriceBox audiobook={audiobook} licenses={licenses} isClubMember={user?.isClubMember} />
  ) : (
    <NotAvailable className={css.notAvailable} />
  );

  const alert = audiobook.web_alert || audiobook.alert;
  const attachmentList = audiobook._embedded["app:attachment"];

  return (
    <div className={css.audiobook}>
      <PriceBoxDesktop mainRef={mainRef}>{priceBox}</PriceBoxDesktop>
      <div className={classNames(css.main, { [css.main__dark]: darkMode })} ref={mainRef}>
        <img
          alt=""
          // eslint-disable-next-line react/no-unknown-property
          fetchPriority="high"
          className={classNames(css.bgImage, { [css.bgImage__blur]: !darkMode })}
          src={`${audiobook.image_url}?w=300&auto=format`}
        />
        <ProductTop
          audiobook={audiobook}
          available={isAvailable}
          canListen={canListen}
          licenses={licenses}
          onReviews={scrollToReviews}
        />
        <div className={classNames(css.content, { [css.content__dark]: darkMode })}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Column span={{ tablet: 6 }} $offset={{ tablet: 4 }}>
                <div className={css.priceContainer}>{priceBox}</div>
              </Grid.Column>
              <Grid.Column span={{ laptop: 8 }}>
                <section>
                  {alert && (
                    <Alert centered mb="32px">
                      <HtmlToReact html={alert} />
                    </Alert>
                  )}
                  <Description audiobook={audiobook} />
                  {attachmentList.length > 0 && canListen && <AttachmentList list={attachmentList} />}
                  <div className={css.sectionSeparator} />
                  <HowToListen />
                  <div className={css.sectionSeparator} />
                  {!getIsOnShelf(licenses) && user?.isClubMember && audiobook.is_available_in_subscription && (
                    <Grid.Row>
                      <Grid.Column span={{ tablet: 6 }} $offset={{ tablet: 4 }}>
                        <ClubMemberMobile audiobook={audiobook} licenses={licenses} />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <ReviewsBox canRate={canRate} audiobook={audiobook} ref={reviewsRef} />
                </section>
              </Grid.Column>
            </Grid.Row>
          </Grid.Container>
        </div>
      </div>
    </div>
  );
};

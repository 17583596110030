import React from "react";
import classNames from "classnames";

import { NewIcon } from "ui/NewIcon";
import { Tooltip } from "modules/DesignSystem/components/tooltip";

import css from "./info-icon.module.scss";

type Props = {
  info: string;
  tooltipDistance?: number;
  iconSize?: number;
  tooltipPaddingBlock?: number;
  className?: string;
};

export function InfoIcon({ info, tooltipDistance, iconSize = 20, tooltipPaddingBlock, className }: Props) {
  const infoRef = React.useRef<SVGSVGElement>(null);

  return (
    <>
      <NewIcon
        className={classNames(className, css.infoIcon)}
        icon="info"
        width={iconSize}
        height={iconSize}
        style={{ "--min-width": `${iconSize}px` } as React.CSSProperties}
        ref={infoRef}
      />
      <Tooltip trigger={infoRef} distance={tooltipDistance} paddingBlock={tooltipPaddingBlock}>
        {info}
      </Tooltip>
    </>
  );
}

import React from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";

import { NewIcon } from "ui/NewIcon";
import { Text } from "ui/text";
import { Link } from "ui/link";

import css from "./how-to-listen.module.scss";

export function HowToListen() {
  const t = useTranslations("product.price_box.how_to_listen");

  return (
    <div className={css.howToListen}>
      <Text type="heading4" as="h2" m="0">
        {t("title")}
      </Text>
      <Text type="bodyNormal" m="0">
        {t("devices")}
      </Text>
      <div className={css.devices}>
        <div className={css.device}>
          <NewIcon icon="mobile-phone" width="24" height="24" />
          <Text type="subtitleBig" m="0">
            Android
          </Text>
        </div>
        <div className={css.device}>
          <NewIcon icon="mobile-phone" width="24" height="24" />
          <Text type="subtitleBig" m="0">
            iOS
          </Text>
        </div>
      </div>
      <div>
        <Text type="bodyNormal" mt="0" mb="8px">
          {t("apps")}
        </Text>
        <div className={css.appLinks}>
          <Link className={css.linkItem} href={`${process.env.AUDIOTEKA_LEGACY_WEB_URL}/[locale]/l/android`}>
            <Image className={css.image} src="/gp-download.png" alt="Get it on Google Play" width="132" height="38" />
          </Link>
          <Link className={css.linkItem} href={`${process.env.AUDIOTEKA_LEGACY_WEB_URL}/[locale]/l/ios`}>
            <Image
              className={css.image}
              src="/as-download.png"
              alt="Download on the App Store"
              width="132"
              height="38"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import Title from "modules/DesignSystem/components/Title";
import { Audiobook } from "resources/AudiotekaApi";
import { ButtonPrimitive } from "ui/button/button-primitive";

import css from "./description.module.scss";

export function Description({ audiobook }: { audiobook: Audiobook }) {
  const t = useTranslations();
  const [isExtendable, setIsExtendable] = React.useState(false);
  const [isExtended, setIsExtended] = React.useState(false);

  const articleRef = React.useCallback((node: HTMLElement) => {
    if (node !== null) {
      const { height } = node.getBoundingClientRect();
      if (height > 180) {
        setIsExtendable(true);
      }
    }
  }, []);

  return (
    <>
      <Title first>{t("product.description")}</Title>
      <article
        ref={articleRef}
        className={classNames(css.description, {
          [css.description__extendable]: isExtendable,
          [css.description__extended]: isExtended,
        })}
        dangerouslySetInnerHTML={{ __html: audiobook.description_html }}
      />
      {isExtendable && (
        <ButtonPrimitive
          onClick={() => setIsExtended((extended) => !extended)}
          className={classNames(css.extendButton, {
            [css.extendButton__extended]: isExtended,
          })}
        >
          {isExtended ? t("product.description_less") : t("product.description_more")}
        </ButtonPrimitive>
      )}
    </>
  );
}

export default Description;

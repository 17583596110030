import React from "react";
import { useTranslations } from "next-intl";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { PricesWithCurrency } from "modules/Audiobook/utils";
import { Text } from "ui/text";

import { Box, BoxContent, BoxContentSubtitle, Price, Title } from "../components";
import { AddToCartButton } from "../buttons";
import css from "./buy-with-special-club-price.module.scss";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
};

export function BuyWithSpecialClubPrice({ audiobook, licenses, price }: Props) {
  const t = useTranslations();

  return (
    <Box>
      <Title>{t("product.price_box.title.buy")}</Title>
      <BoxContent>
        <div>
          <Text type="subtitleNormal" className={css.subtitle}>
            {t("product.price_box.title.buy_optional")}
          </Text>
          <Price club>{price.club}</Price>
        </div>
        <AddToCartButton audiobook={audiobook} licenses={licenses} />
        <BoxContentSubtitle className={css.noClubPrice}>
          {t("product.price_box.price_without_club", { price: price.base })}
        </BoxContentSubtitle>
      </BoxContent>
    </Box>
  );
}

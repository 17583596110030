import React from "react";

import { ProductReview } from "resources/AudiotekaApi";

import { ReviewItem } from "../review-item/review-item";
import css from "./reviews-list.module.scss";

export function ReviewsList({ limit, reviews }: { limit?: number; reviews: ProductReview[] }) {
  const list = reviews || [];
  const reviewList = typeof limit === "number" ? list.slice(0, limit) : list;

  return (
    <div className={css.list}>
      {reviewList.map((item) => (
        <ReviewItem key={item._embedded["app:review"]?.id} reviewItem={item} />
      ))}
    </div>
  );
}

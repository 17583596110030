import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslations } from "next-intl";

import { useUser } from "store/useUser";
import { Audiobook, getUserProductReview, ProductReview } from "resources/AudiotekaApi";
import { Text } from "ui/text";
import { Link } from "ui/link";
import { Button } from "ui/button/button";

import { ReviewsList } from "./reviews-list/reviews-list";
import { ReviewBoxTop } from "./review-box-top/review-box-top";
import { ReviewsSlideBar } from "./reviews-slide-bar/reviews-slide-bar";
import { ReviewForm } from "./review-form/review-form";
import { AIReview } from "./ai-review/ai-review";
import css from "./reviews-box.module.scss";

interface Props {
  canRate: boolean;
  audiobook: Audiobook;
}

export const ReviewsBox = forwardRef<HTMLDivElement, Props>(({ canRate, audiobook }, ref) => {
  const t = useTranslations("product.reviews");
  const user = useUser((state) => state.data);

  const [formVisible, setFormVisible] = useState(false);
  const [moreVisible, setMoreVisible] = useState(false);
  const [review, setReview] = useState<ProductReview | null>(null);

  const updateReview = useCallback(
    async (newReview?: ProductReview) => {
      if (newReview) {
        setReview(newReview);
      } else if (user) {
        try {
          const { data: existingReview } = await getUserProductReview(audiobook.id);
          setReview(existingReview);
        } catch {
          setReview(null);
        }
      } else {
        setReview(null);
      }
    },
    [user, audiobook]
  );

  useEffect(() => {
    if (user) {
      updateReview();
    }
  }, [updateReview, user?.id]);

  const toggleFormVisible = useCallback(() => {
    if (!formVisible && review && (review._embedded?.["app:review"] || review._embedded?.["app:rating"])) {
      setMoreVisible(true);
    } else {
      setFormVisible((visible) => !visible);
    }
  }, [formVisible, review]);

  const toggleMoreVisible = useCallback(() => {
    setMoreVisible((visible) => !visible);
  }, []);

  const handleRateBtn = useCallback(() => {
    setMoreVisible(false);
    setFormVisible(true);
  }, []);

  const handleReviewSubmit = useCallback(async (newReview: ProductReview) => {
    await updateReview(newReview);

    setFormVisible(false);
    setMoreVisible(true);
  }, []);

  const reviewBaseList = audiobook._embedded["app:user-product-review"];

  return (
    <div className={css.reviewsBox} ref={ref}>
      <ReviewBoxTop audiobook={audiobook} canRate={canRate} showForm={toggleFormVisible} />
      <AIReview review={audiobook.ai_review} />
      {reviewBaseList.length > 0 && (
        <div className={css.reviews}>
          <ReviewsList reviews={reviewBaseList} limit={3} />
          {reviewBaseList.length > 3 && (
            <Button className={css.showMore} outlined onClick={toggleMoreVisible}>
              {t("show_all")}
            </Button>
          )}
        </div>
      )}
      <Text type="bodyNormal" m="0" className={css.reviewsInfo}>
        {t.rich("moderation_info", {
          // eslint-disable-next-line react/no-unstable-nested-components
          link1: (chunks) => (
            <Link className={css.reviewsInfoLink} route="reviewTerms" target="_blank">
              {chunks}
            </Link>
          ),
        })}
      </Text>

      <ReviewsSlideBar
        canRate={canRate}
        open={moreVisible}
        onClose={toggleMoreVisible}
        onRate={handleRateBtn}
        audiobook={audiobook}
        review={review}
      />

      <ReviewForm
        audiobook={audiobook}
        open={formVisible}
        review={review}
        onClose={toggleFormVisible}
        onReview={handleReviewSubmit}
      />
    </div>
  );
});

import React from "react";
import { useTranslations } from "next-intl";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";

import { InfoIcon } from "../../../info-icon/info-icon";
import { Box, BoxContent, FooterParagraph, Price, Title } from "../components";
import { AddToCartButton } from "../buttons";
import type { PricesWithCurrency } from "../../../../utils";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
  isPremium: boolean;
};

export function BuyOnlyProduct({ audiobook, licenses, price, isPremium }: Props) {
  const t = useTranslations();

  return (
    <Box>
      <Title>
        {t("product.price_box.title.buy")}{" "}
        <InfoIcon
          info={`${t("product.price_box.info.buy_only")} ${
            isPremium ? t("product.price_box.info.buy_only_premium") : ""
          }`}
        />
      </Title>
      <BoxContent>
        <Price>
          {price.final !== price.base ? (
            <Price as="span" old>
              {price.base}
            </Price>
          ) : null}
          {price.final}
        </Price>
        <AddToCartButton audiobook={audiobook} licenses={licenses} />
        {price.lowest && price.final !== price.base ? (
          <FooterParagraph>
            {t("product.lowest_price", { price: price.lowest.replace(/ /g, "\xa0") }).replace(/: /g, ":\xa0")}
          </FooterParagraph>
        ) : null}
      </BoxContent>
    </Box>
  );
}

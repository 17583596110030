import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Audiobook } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";
import { Text } from "ui/text";
import { ButtonLink } from "ui/button/button-link";

import { Box, Title } from "../components";
import { PlayButton } from "../buttons";
import { DownloadBox } from "./download/download-box";
import css from "./product-on-shelf.module.scss";

interface Props {
  audiobook: Audiobook;
  isShared: boolean;
  canDownload: boolean;
}

export const ProductOnShelf = ({ audiobook, isShared, canDownload }: Props) => {
  const t = useTranslations();

  return (
    <Box>
      <div>
        <Title>
          <NewIcon className={css.shelfIcon} icon="shelf" width="24" height="24" />
          {t("product.price_box.title.shelf")}
        </Title>
        {isShared && (
          <Text className={css.familyShare} type="bodySmall">
            {t("product.price_box.familyShare")}
          </Text>
        )}
      </div>
      <div
        className={classNames(css.actionButtons, {
          [css.actionButtons__downloadableAbook]: canDownload,
        })}
      >
        <PlayButton audiobook={audiobook} />
        <ButtonLink className={css.shelfButton} outlined route="shelf">
          <NewIcon icon="shelf" width="24" height="24" />
          {t("product.price_box.cta.shelf")}
        </ButtonLink>
      </div>
      {canDownload && <DownloadBox audiobook={audiobook} />}
    </Box>
  );
};

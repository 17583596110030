/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { PricesWithCurrency } from "modules/Audiobook/utils";
import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { Box, BoxContent, BoxContentSubtitle, BoxContentTitle, Title } from "../components";
import { AddToCartButton, ClubJoinButton } from "../buttons";
import { ArgumenterList } from "../common/common";
import css from "./buy-with-club.module.scss";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
  clubListen: boolean;
};

function RadioButton() {
  return (
    <div className={css.radioButton}>
      <NewIcon className={css.checkedRadioButton} icon="radio-button-checked" width="24" height="24" />
      <NewIcon className={css.uncheckedRadioButton} icon="radio-button" width="24" height="24" />
    </div>
  );
}

export function BuyWithClub({ audiobook, licenses, price, clubListen }: Props) {
  const t = useTranslations();

  const subTitle = clubListen ? t("product.price_box.subtitle.club_available") : price.club;
  const description = clubListen
    ? t("product.price_box.subtitle.club_available_description")
    : t("product.price_box.info.club_special_price");
  const isPromo = price.final !== price.base;
  const isClubPromo = isPromo && price.club === price.final;

  const [selectedBox, setSelectedBox] = React.useState<"club" | "base">("club");

  const getAnimationWrapperClassNames = (box: "club" | "base") =>
    classNames(css.animationWrapper, { [css.animationWrapper__hidden]: selectedBox !== box });

  return (
    <Box>
      <Title>{t("product.price_box.title.club_free")}</Title>
      <div
        className={classNames(css.option, {
          [css.option__checked]: selectedBox === "club",
        })}
        onClick={() => setSelectedBox("club")}
      >
        <BoxContent className={css.boxContent}>
          <div>
            <div className={css.boxHeader}>
              <BoxContentTitle club={!clubListen}>{subTitle}</BoxContentTitle>
              <RadioButton />
            </div>
            <BoxContentSubtitle>{description}</BoxContentSubtitle>
          </div>
          <div className={getAnimationWrapperClassNames("club")}>
            <div className={css.contentWrapper}>
              <ClubJoinButton />
            </div>
          </div>
          <ArgumenterList isClubListenAvailable={clubListen} />
        </BoxContent>
      </div>
      <div
        className={classNames(css.option, {
          [css.option__checked]: selectedBox === "base",
        })}
        onClick={() => setSelectedBox("base")}
      >
        <BoxContent className={css.boxContent}>
          <div>
            <div className={css.boxHeader}>
              <BoxContentTitle>
                {isPromo && <span className={css.oldPrice}>{price.base}</span>}
                {price.final}
              </BoxContentTitle>
              <RadioButton />
            </div>
            <BoxContentSubtitle>{t("product.price_box.subtitle.outside_club")}</BoxContentSubtitle>
          </div>
          <div className={getAnimationWrapperClassNames("base")}>
            <div className={css.contentWrapper}>
              <AddToCartButton audiobook={audiobook} licenses={licenses} />
              {price.lowest && isPromo && !isClubPromo && (
                <BoxContentSubtitle className={css.lowestPrice}>
                  {t("product.lowest_price", { price: price.lowest.replace(/ /g, "\xa0") })}
                </BoxContentSubtitle>
              )}
            </div>
          </div>
        </BoxContent>
      </div>
    </Box>
  );
}

import React from "react";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { PricesWithCurrency } from "modules/Audiobook/utils";
import { useUser } from "store/useUser";

import { BuyWithClub } from "./buy-with-club";
import { BuyOnlyProduct } from "./buy-only-product";
import { BuyWithSpecialClubPrice } from "./buy-with-special-club-price";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
  clubListen: boolean;
};

export function ProductToBuy({ audiobook, licenses, price, clubListen }: Props) {
  const isPremium = audiobook._embedded["app:context"].is_premium;
  const withClub = (price.club && price.club !== price.final) || clubListen;
  const user = useUser((state) => state.data);

  const withSpecialClubPrice = user?.isClubMember && !!price.base && !!price.club && price.club === price.final;

  if (withSpecialClubPrice) {
    return <BuyWithSpecialClubPrice audiobook={audiobook} licenses={licenses} price={price} />;
  }

  if (isPremium || !withClub) {
    return <BuyOnlyProduct audiobook={audiobook} licenses={licenses} price={price} isPremium={isPremium} />;
  }

  return <BuyWithClub audiobook={audiobook} licenses={licenses} price={price} clubListen={clubListen} />;
}

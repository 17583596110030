import React from "react";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";

import { Text } from "ui/text";
import { ButtonPrimitive } from "ui/button/button-primitive";
import { Audiobook } from "resources/AudiotekaApi";

import { AbookIcon } from "../../../../abook-icon";
import { Options } from "../../options";
import css from "./download-box.module.scss";

const DownloadModal = dynamic(() => import("./download-modal"), { ssr: false });

interface Props {
  audiobook: Audiobook;
}

export const DownloadBox = ({ audiobook }: Props) => {
  const t = useTranslations();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <Options summary={t("product.price_box.options.general")}>
      <div className={css.contentBox}>
        <Text type="subtitleNormal" className={css.header}>
          {t("product.price_box.mp3.header")}
        </Text>
        <Text type="bodySmall" className={css.paragraph}>
          {t("product.price_box.mp3.text")}
        </Text>
        <ButtonPrimitive className={css.downloadButton} onClick={() => setIsModalVisible(true)}>
          <AbookIcon icon="download" width="16" height="16" />
          {t("product.price_box.mp3.download", { fileSize: 17.7 })}
        </ButtonPrimitive>
        {isModalVisible && (
          <DownloadModal onClose={() => setIsModalVisible(false)} open={isModalVisible} audiobook={audiobook} />
        )}
      </div>
    </Options>
  );
};

import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Audiobook } from "resources/AudiotekaApi";
import { ButtonPrimitive } from "ui/button/button-primitive";

import { Collections } from "./collections";
import { Details } from "./details/details";
import css from "./more-info.module.scss";

type Props = {
  audiobook: Audiobook;
  available: boolean;
};

export const MoreInfo = ({ audiobook, available }: Props) => {
  const t = useTranslations();

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleMoreInfo = () => {
    setIsVisible((visible) => !visible);
  };

  return (
    <>
      <ButtonPrimitive
        className={classNames(css.button, { [css.button__visible]: isVisible })}
        onClick={toggleMoreInfo}
      >
        {t("product.more_info")}
      </ButtonPrimitive>
      <div className={classNames(css.content, { [css.content__visible]: isVisible })}>
        <div>
          <Details audiobook={audiobook} />
          {available ? <Collections audiobook={audiobook} /> : null}
        </div>
      </div>
    </>
  );
};

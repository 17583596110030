import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Button } from "ui/button";
import { usePlayer } from "modules/player";
import { useUser } from "store/useUser";
import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";

import { getIsOnShelf } from "../../../utils";
import { AbookIcon } from "../../abook-icon";
import { Description } from "./description";
import css from "./play-button.module.scss";

type Props = {
  className?: string;
  available: boolean;
  audiobook: Audiobook;
  licenses: LicenseChannels;
  canListen: boolean;
};

const getButtonColor = (isFree: boolean, isOnShelf: boolean, clubListen: boolean, isClubMember: boolean) => {
  if (isFree || isOnShelf) return "white";

  if (clubListen && isClubMember) {
    return "club";
  }

  return "white";
};

export const PlayButton = ({ className, available, audiobook, licenses, canListen }: Props) => {
  const t = useTranslations();
  const player = usePlayer();
  const user = useUser((state) => state.data);

  if (!available) return null;

  const handlePlay = async () => {
    if (player.audiobook?.id !== audiobook.id) {
      await player.load(audiobook.id, { autoPlay: true });
    } else {
      player.play();
    }
  };

  const isOnShelf = getIsOnShelf(licenses);
  const color = getButtonColor(
    audiobook.is_free,
    isOnShelf,
    audiobook.is_available_in_subscription,
    user?.isClubMember
  );

  return (
    <div className={classNames(className, css.playButtonWrapper)}>
      <Button className={css.playButton} onClick={handlePlay} color={color}>
        <AbookIcon icon="play-alt" width="24" height="24" />
        {t("product.listen")}{" "}
        {!canListen && t("product.sample", { sampleLength: audiobook.sample_duration_in_minutes })}
      </Button>
      <Description audiobook={audiobook} licenses={licenses} />
    </div>
  );
};

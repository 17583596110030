import React, { ComponentProps } from "react";

import { Link } from "ui/link";

import css from "./tag.module.scss";

export const Tag = ({ children, ...props }: { children: React.ReactNode } & ComponentProps<typeof Link>) => {
  return (
    <Link className={css.tag} {...props}>
      {children}
    </Link>
  );
};

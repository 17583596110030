import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { isServer } from "utils/runtime";
import { disableScroll, enableScroll } from "utils/window";
import { NewIcon } from "ui/NewIcon";

import { Backdrop, Close, Container, Content, ContentNoScroll, Root } from "./styled";

export type SlideBarSide = "left" | "right";

interface Props extends PropsWithChildren {
  dark?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLElement>;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  open: boolean;
  side?: SlideBarSide;
  width?: number;
}

const SlideBar = ({ children, dark, footer, header, open, onClose, onScroll, side = "right", width }: Props) => {
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);
  const contentRef = useRef<HTMLDivElement>();
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    document.body.appendChild(root);
    setMounted(true);

    return () => {
      setMounted(false);
      document.body.removeChild(root);
      enableScroll(root);
    };
  }, []);

  useEffect(() => {
    if (open) {
      disableScroll(root);
    } else {
      enableScroll(root);
    }
  }, [open]);

  useEffect(() => {
    if (open && contentRef.current) {
      contentRef.current.scrollTo({ behavior: "auto", top: 0 });
    }
  }, [open]);

  return isMounted
    ? ReactDOM.createPortal(
        <Root active={open}>
          <Backdrop onClick={onClose} />
          <Container dark={dark} side={side} width={width}>
            <Close onClick={onClose}>
              <NewIcon className="slide-bar__close-icon" icon="close" width="24" height="24" />
            </Close>
            {header && <ContentNoScroll type="header">{header}</ContentNoScroll>}
            <Content onScroll={onScroll} ref={contentRef}>
              {children}
            </Content>
            {footer && <ContentNoScroll type="footer">{footer}</ContentNoScroll>}
          </Container>
        </Root>,
        root
      )
    : null;
};

export default SlideBar;

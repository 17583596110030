import React from "react";

import { Loader as UILoader } from "ui/loader";

import css from "./loader.module.scss";

export function Loader() {
  return (
    <div className={css.loader}>
      <UILoader />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import * as Sentry from "@sentry/nextjs";

import { ButtonPrimitive } from "ui/button/button-primitive";
import { NewIcon } from "ui/NewIcon";
import { useFavourites } from "modules/favourites/favourites.provider";
import { useUser } from "store/useUser";
import { getProductRelations } from "resources/AudiotekaApi";

import css from "./favourites-button.module.scss";

export const FavouritesButton = ({ audiobookId }: { audiobookId: string }) => {
  const t = useTranslations();
  const { count, like, dislike } = useFavourites();
  const user = useUser((state) => state.data);

  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    async function fetchProductRelations() {
      try {
        const {
          data: { relations },
        } = await getProductRelations([audiobookId]);

        setIsFavourite(relations[0].favorite);
      } catch (e) {
        Sentry.captureException(`FavouritesButton | Failed to get product relations | Error: ${e}`);
      }
    }

    if (user?.id) {
      fetchProductRelations();
    }
  }, [count, audiobookId, user?.id]);

  return (
    <ButtonPrimitive
      className={css.favouritesButton}
      onClick={() => (isFavourite ? dislike(audiobookId) : like(audiobookId))}
    >
      <NewIcon className={css.icon} icon={isFavourite ? "heart" : "heart-border"} height="32" width="32" />
      {isFavourite ? t("product.favourites_remove") : t("product.favourites_add")}
    </ButtonPrimitive>
  );
};

import React, { HTMLAttributes } from "react";
import classNames from "classnames";

import css from "./alert.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
  type?: "info" | "error";
  children: React.ReactNode;
}

export function Alert({ className, centered, type = "info", children, ...props }: Props) {
  return (
    <div
      className={classNames(className, css.alert, css[`alert__${type}`], { [css.alert__centered]: centered })}
      {...props}
    >
      {children}
    </div>
  );
}

import React from "react";
import classNames from "classnames";

import { NewIcon } from "ui/NewIcon";
import { ButtonPrimitive } from "ui/button/button-primitive";

import css from "./options.module.scss";

export const Options = ({ summary, children }: { summary: string; children: React.ReactNode }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <div className={css.options}>
      <ButtonPrimitive
        className={css.summary}
        onClick={() => {
          setIsVisible((_isVisible) => !_isVisible);
        }}
      >
        {summary}
        <NewIcon icon={isVisible ? "chevron-up" : "chevron-down"} width="14" height="14" />
      </ButtonPrimitive>
      <div className={classNames(css.content, { [css.content__visible]: isVisible })}>
        <div className={css.childrenWrapper}>{children}</div>
      </div>
    </div>
  );
};

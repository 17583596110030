import React from "react";
import classNames from "classnames";
import { useLocale } from "next-intl";

import { NewIcon } from "ui/NewIcon";
import { Text } from "ui/text";
import { ProductReview } from "resources/AudiotekaApi";

import css from "./review-item.module.scss";

interface Props {
  reviewItem: ProductReview;
  accent?: boolean;
}

function getDate(reviewed_at: string, locale: string) {
  if (!reviewed_at) {
    return "";
  }

  const dateFormat = Intl.DateTimeFormat(locale, { dateStyle: "short" });

  return dateFormat.format(new Date(reviewed_at));
}

function Stars({ rating }: { rating: number }) {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const icon = (function getStarIcon() {
      if (rating >= index + 1) return "star";

      if (Math.ceil(rating) === index + 1) return "star-half";

      return "star-empty";
    })();

    return <NewIcon key={`star-${index}`} icon={icon} width="14" height="14" />;
  });

  return <div className={css.stars}>{stars}</div>;
}

export function ReviewItem({ reviewItem, accent }: Props) {
  const rating = reviewItem._embedded["app:rating"];
  const review = reviewItem._embedded["app:review"];
  const locale = useLocale();

  return (
    <div className={classNames(css.reviewItem, { [css.reviewItem__accent]: accent })}>
      <div className={css.header}>
        <div className={css.author}>
          <div className={css.authorIcon}>
            <NewIcon icon="user-new" width="20" height="20" />
          </div>
          <Text type="subtitleBig" m="0" className={css.authorName}>
            {review.author_signature}
          </Text>
        </div>
        <Stars rating={rating?.value} />
        <Text type="bodyNormal" m="0" mt="2px" className={css.reviewDate}>
          {getDate(review?.reviewed_at, locale)}
        </Text>
      </div>
      <Text type="bodyBig" m="0" className={css.review}>
        {review?.review}
      </Text>
    </div>
  );
}

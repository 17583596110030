import React from "react";
import { useTranslations } from "next-intl";

import { Box, BoxContent, BoxContentSubtitle, BoxContentTitle, Title } from "../components";
import { ClubJoinButton } from "../buttons";
import { ArgumenterList } from "../common/common";

export const ClubOnlyProduct = () => {
  const t = useTranslations();

  return (
    <Box>
      <Title>{t("product.price_box.title.club_free")}</Title>
      <BoxContent>
        <div>
          <BoxContentTitle> {t("product.price_box.subtitle.club_available")}</BoxContentTitle>
          <BoxContentSubtitle>{t("product.price_box.subtitle.club_available_description")}</BoxContentSubtitle>
        </div>
        <ClubJoinButton />
        <ArgumenterList isClubListenAvailable />
      </BoxContent>
    </Box>
  );
};

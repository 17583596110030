import React from "react";
import { useTranslations } from "next-intl";

import { ButtonPrimitive } from "ui/button/button-primitive";

import { StarIcon } from "./star-icon";
import css from "./rating-button.module.scss";

export const RatingButton = ({ rating, onReviews }: { rating: number; onReviews: () => void }) => {
  const t = useTranslations();

  return (
    <ButtonPrimitive className={css.button} onClick={onReviews}>
      <span className={css.mobileText}>{t("product.average_rating")}</span>
      <span className={css.desktopText}>{t("product.average_rating_short")}</span>
      <StarIcon rating={rating} />
    </ButtonPrimitive>
  );
};

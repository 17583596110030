import { useEffect } from "react";
import { useRouter } from "next/router";
import gtm from "react-gtm-module";

import { getGTMProduct, getListData } from "utils/products";
import { useUser } from "store/useUser";
import { Audiobook } from "resources/AudiotekaApi";

import { getPrices } from "./utils";

export const useAnalytics = (audiobook: Audiobook, currency: string) => {
  const {
    query: { locale },
  } = useRouter();
  const isUserFetched = useUser((state) => state.status === "fetched");
  const user = useUser((state) => state.data);

  useEffect(() => {
    if (!isUserFetched) {
      return;
    }

    const prices = getPrices(audiobook, undefined, user?.isClubMember);
    const listData = getListData(audiobook.id);

    gtm.dataLayer({
      dataLayer: {
        event: "view_item",
        ecommerce: {
          currency,
          value: prices.final,
          items: [
            getGTMProduct({
              product: audiobook,
              audiobook,
              ...listData,
            }),
          ],
        },
      },
    });
  }, [locale, audiobook, isUserFetched, user?.isClubMember]);
};

import React from "react";
import classNames from "classnames";

import { Text } from "ui/text";

import css from "./components.module.scss";

export function Box({ className, ...props }: React.ComponentProps<"div">) {
  return <div className={classNames(className, css.box)} {...props} />;
}

export function BoxContent({ className, ...props }: React.ComponentProps<"div">) {
  return <div className={classNames(className, css.boxContent)} {...props} />;
}

export function BoxContentTitle({ club, children }: { club?: boolean; children: React.ReactNode }) {
  return (
    <Text className={classNames(css.boxContentTitle, { [css.boxContentTitle__club]: club })} type="heading5">
      {children}
    </Text>
  );
}

export function BoxContentSubtitle({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <Text className={classNames(className, css.boxContentSubtitle)} type="bodySmall">
      {children}
    </Text>
  );
}

export function Title({ className, ...props }: React.ComponentProps<"p">) {
  return <Text type="heading6" as="p" className={classNames(className, css.title)} {...props} />;
}

type PriceProps = {
  as?: "p" | "span";
  club?: boolean;
  old?: boolean;
  children: React.ReactNode;
};

export function Price({ as: Tag = "p", club, old, children }: PriceProps) {
  return <Tag className={classNames(css.price, { [css.price__club]: club, [css.price__old]: old })}>{children}</Tag>;
}

export function FooterParagraph({ className, ...props }: React.ComponentProps<"p">) {
  return <Text type="bodySmall" className={classNames(className, css.footerParagraph)} {...props} />;
}

import React from "react";
import { useTranslations } from "next-intl";

import { Text } from "ui/text";

import { AbookIcon } from "../../abook-icon";
import css from "./ai-review.module.scss";

export function AIReview({ review }: { review: string }) {
  const t = useTranslations("product.reviews.ai_review");

  if (!review) return null;

  return (
    <div className={css.aiReviewWrapper}>
      <div className={css.aiReview}>
        <div className={css.header}>
          <AbookIcon className={css.icon} icon="astronomy" />
          <Text as="h3" type="subtitleBig" m="0">
            {t("header")}
          </Text>
        </div>
        <Text type="bodyBig" m="0">
          {review}
        </Text>
      </div>
    </div>
  );
}

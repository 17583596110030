import React from "react";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import Grid from "components/Grid";
import { Link } from "ui/link";

import { NotAvailable } from "../price-box";
import { PlayButton } from "./play-button/play-button";
import { FavouritesButton } from "./action-buttons/favourites-button";
import { RatingButton } from "./action-buttons/rating-button";
import { MoreInfo } from "./more-info";
import css from "./product-top.module.scss";

interface Props {
  audiobook: Audiobook;
  available: boolean;
  licenses: LicenseChannels;
  canListen: boolean;
  onReviews: () => void;
}

export const ProductTop = ({ audiobook, available, licenses, canListen, onReviews }: Props) => {
  const authors = audiobook._embedded["app:author"];

  return (
    <Grid.Container className={css.productTop}>
      <Grid.Row className={css.productTopRow} id="product-top">
        <Grid.Column span={{ _: 10, tablet: 4, laptop: 3 }} $offset={{ _: 2, tablet: 5, laptop: 1 }}>
          <img className={css.cover} alt={audiobook.name} src={`${audiobook.image_url}?w=300&auto=format`} />
          <PlayButton
            className={css.playButton__desktop}
            available={available}
            audiobook={audiobook}
            licenses={licenses}
            canListen={canListen}
          />
        </Grid.Column>
        <Grid.Column
          className={css.detailsColumn}
          span={{ tablet: 6, laptop: 5, desktop: 6 }}
          $offset={{ tablet: 4, laptop: "auto" }}
        >
          <h1 className={css.title}>{audiobook.name}</h1>
          <div className={css.authors}>
            {authors.map((author, index) => {
              const isLast = index === authors.length - 1;
              return (
                <React.Fragment key={author.id}>
                  <Link className={css.author} route="author" params={{ id: author.slug || author.id }}>
                    {author.name}
                  </Link>
                  {isLast ? "" : <span className={css.comma}>, </span>}
                </React.Fragment>
              );
            })}
          </div>
          <PlayButton
            className={css.playButton__mobile}
            available={available}
            audiobook={audiobook}
            licenses={licenses}
            canListen={canListen}
          />
          {!available && <NotAvailable className={css.notAvailableMobile} />}
          {available && (
            <div className={css.actions}>
              <RatingButton rating={Math.floor(audiobook.rating * 10) / 10} onReviews={onReviews} />
              <FavouritesButton audiobookId={audiobook.id} />
            </div>
          )}

          <MoreInfo audiobook={audiobook} available={available} />
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
};

import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { Text } from "ui/text";

import { AddToCartButton, PlayButton } from "../buttons";
import { Options } from "../options";
import { Box, BoxContent, BoxContentSubtitle, Price, Title } from "../components";
import type { PricesWithCurrency } from "../../../../utils";

import css from "./club-member.module.scss";

interface Props {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
}

export const ClubMember = ({ audiobook, licenses, price }: Props) => {
  const t = useTranslations();

  const canBuy = !!price.base;

  const isClubPrice = price.final === price.club;

  return (
    <Box
      className={classNames(css.clubMember, {
        [css.clubMember__clubOnly]: !canBuy,
      })}
    >
      <Title>{t("product.price_box.title.club_available")}</Title>
      <PlayButton audiobook={audiobook} club />
      {canBuy && (
        <Options summary={t("product.price_box.options.buy")}>
          <BoxContent className={css.contentBox}>
            <div className={css.contentBoxInfo}>
              {isClubPrice && (
                <Text type="subtitleNormal" className={css.subTitle}>
                  {t("product.price_box.title.buy_optional")}
                </Text>
              )}
              <Price club={isClubPrice}>
                {price.final !== price.base && price.final !== price.club ? <Price old>{price.base}</Price> : null}
                {price.final}
              </Price>
            </div>
            <AddToCartButton audiobook={audiobook} licenses={licenses} outlined />
            {isClubPrice && (
              <BoxContentSubtitle className={css.noClubPrice}>
                {t("product.price_box.price_without_club", { price: price.base })}
              </BoxContentSubtitle>
            )}
            {price.lowest && !isClubPrice && (
              <BoxContentSubtitle className={css.lowestPrice}>
                {t("product.lowest_price", { price: price.lowest.replace(/ /g, "\xa0") })}
              </BoxContentSubtitle>
            )}
          </BoxContent>
        </Options>
      )}
    </Box>
  );
};

import React, { type SVGProps } from "react";

type IconName =
  | "play-alt"
  | "rate"
  | "astronomy"
  | "badge"
  | "download"
  | "download-alt"
  | "cover"
  | "list"
  | "window"
  | "apple";

export function AbookIcon({ icon, ...props }: SVGProps<SVGSVGElement> & { icon: IconName }) {
  return (
    <svg {...props}>
      <use href={`/img/audiobook-icons-14022025.svg#${icon}`} />
    </svg>
  );
}

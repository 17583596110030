import React from "react";
import { useTranslations } from "next-intl";

export function FormatRow() {
  const t = useTranslations("product.format");

  return (
    <tr>
      <td>{t("label")}</td>
      <td>{t("value")}</td>
    </tr>
  );
}

import React from "react";
import { useTranslations } from "next-intl";

import { Link } from "ui/link";
import { Button } from "ui/button/button";
import { Text } from "ui/text";
import { Audiobook } from "resources/AudiotekaApi";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";
import { useUser } from "store/useUser";

import { RatingBadge } from "../rating-badge/rating-badge";
import css from "./header.module.scss";

interface Props {
  canRate: boolean;
  audiobook: Audiobook;
  onRate: () => void;
}

function RatingOptions({ canRate, onRate }: Omit<Props, "audiobook">) {
  const user = useUser((state) => state.data);
  const t = useTranslations("product.reviews");
  const { signInRedirectParams } = useSignInRedirectParams();

  if (canRate) {
    return (
      <Button onClick={onRate} fluid className={css.rateButton}>
        {t("add_rating")}
      </Button>
    );
  }

  if (user) {
    return (
      <Text type="bodyNormal" m="0" className={css.buyToRate}>
        {t("buy_to_rate")}
      </Text>
    );
  }

  return (
    <Text type="bodyBig" m="0" className={css.loginToRate}>
      {t.rich("login_to_rate", {
        // eslint-disable-next-line react/no-unstable-nested-components
        link1: (chunks) => (
          <Link className={css.loginLink} route="signIn" params={signInRedirectParams}>
            {chunks}
          </Link>
        ),
      })}
    </Text>
  );
}

export function Header({ canRate, audiobook, onRate }: Props) {
  const t = useTranslations();

  return (
    <>
      <Text type="heading3" my={{ _: "16px", tablet: "24px" }}>
        {t("product.reviews.all_title")}
      </Text>
      <div className={css.ratingSection}>
        <RatingBadge audiobook={audiobook} />
        <RatingOptions canRate={canRate} onRate={onRate} />
      </div>
    </>
  );
}

import React from "react";
import { useTranslations } from "next-intl";

import { useUser } from "store/useUser";
import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { getCanListen } from "utils/getCanListen";

import { InfoIcon } from "../../info-icon/info-icon";
import { Box, Title } from "./components";
import { PlayButton } from "./buttons";

import css from "./free-product.module.scss";

interface Props {
  audiobook: Audiobook;
  licenses: LicenseChannels;
}

export const FreeProduct = ({ audiobook, licenses }: Props) => {
  const t = useTranslations();
  const user = useUser((state) => state.data);
  const canListen = getCanListen(licenses);

  return (
    <Box className={css.freeProduct}>
      <Title>
        {t("product.price_box.title.free")}
        {!user && <InfoIcon info={t("product.price_box.info.free")} tooltipDistance={5} tooltipPaddingBlock={6} />}
      </Title>
      <PlayButton audiobook={audiobook} sample={!canListen} />
    </Box>
  );
};

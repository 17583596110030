import React from "react";

import { AbookIcon } from "../../abook-icon";
import css from "./star-icon.module.scss";

export const StarIcon = ({ rating }: { rating: number }) => (
  <div className={css.starIcon}>
    <AbookIcon icon="badge" className={css.icon} />
    <span className={css.label}>{rating.toFixed(1).replace(".", ",")}</span>
  </div>
);

import React from "react";
import { useTranslations } from "next-intl";

import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";
import { Audiobook } from "resources/AudiotekaApi";
import { Button } from "ui/button/button";
import { Link } from "ui/link";
import { Text } from "ui/text";
import { useUser } from "store/useUser";

import { RatingBadge } from "../rating-badge/rating-badge";
import css from "./review-box-top.module.scss";

interface Props {
  audiobook: Audiobook;
  canRate: boolean;
  showForm: () => void;
}

function LoginLink({ children }: { children: React.ReactNode }) {
  const { signInRedirectParams } = useSignInRedirectParams();

  return (
    <Link className={css.loginLink} route="signIn" params={signInRedirectParams}>
      {children}
    </Link>
  );
}

const Action = ({ canRate, showForm }: { canRate: boolean; showForm: () => void }) => {
  const t = useTranslations("product.reviews");
  const user = useUser((state) => state.data);

  if (canRate) {
    return (
      <Button className={css.rateButton} fluid outlined onClick={showForm}>
        {t("add_rating")}
      </Button>
    );
  }

  if (user) {
    return (
      <Text type="bodySmall" m="0" className={css.buyToRate}>
        {t("buy_to_rate")}
      </Text>
    );
  }

  return (
    <Text type="bodyBig" m="0" className={css.login}>
      {t.rich("login_to_rate", {
        // eslint-disable-next-line react/no-unstable-nested-components
        link1: (chunks) => <LoginLink>{chunks}</LoginLink>,
      })}
    </Text>
  );
};

export function ReviewBoxTop({ audiobook, canRate, showForm }: Props) {
  const t = useTranslations("product.reviews");

  return (
    <div className={css.reviewBoxTop}>
      <Text className={css.title} as="h2" type="heading4" m="0">
        {t("title")}
      </Text>
      <RatingBadge className={css.badge} audiobook={audiobook} />
      <div className={css.action}>
        <Action canRate={canRate} showForm={showForm} />
      </div>
    </div>
  );
}

import React from "react";
import styled from "styled-components";

import Grid from "components/Grid";
import { breakpoint } from "modules/DesignSystem/breakpoint";

const topSpace = 32;

const StickyBox = styled.div`
  display: none;

  @media (min-width: ${breakpoint.laptop}) {
    display: block;
    position: sticky;
    top: 140px;
    pointer-events: auto;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  top: ${topSpace}px;
  pointer-events: none;
`;

const GridContainer = styled(Grid.Container)`
  height: 100%;
`;

const GridRow = styled(Grid.Row)`
  height: 100%;
`;

type Props = {
  mainRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
};

const onResize = (entryHeight: number) => {
  const priceBox = document.querySelector("#desktop-price-box") as HTMLElement;
  const stickyBox = document.querySelector("#desktop-price-box__sticky-box") as HTMLElement;
  const productTop = document.querySelector("#product-top") as HTMLElement;

  if (!priceBox || !stickyBox || !productTop) return;

  const productTopFirstChild = productTop.querySelector(":scope > *") as HTMLElement;
  if (!productTopFirstChild) return;

  const productTopY = window.pageYOffset + productTopFirstChild.getBoundingClientRect().top;

  priceBox.style.setProperty("height", `${entryHeight - topSpace}px`);
  stickyBox.style.setProperty("top", `${productTopY}px`);
};

const onTopBarMutate = () => {
  const stickyBox = document.querySelector("#desktop-price-box__sticky-box") as HTMLElement;
  const productTop = document.querySelector("#product-top") as HTMLElement;

  if (!stickyBox || !productTop) return;

  const productTopFirstChild = productTop.querySelector(":scope > *") as HTMLElement;
  if (!productTopFirstChild) return;

  const productTopY = window.pageYOffset + productTopFirstChild.getBoundingClientRect().top;

  stickyBox.style.setProperty("top", `${productTopY}px`);
};

export const PriceBoxDesktop = ({ mainRef, children }: Props) => {
  React.useEffect(() => {
    const element = mainRef.current;

    if (!element) return;
    if (typeof window === "undefined" || !("ResizeObserver" in window)) return;

    const observer = new ResizeObserver(([entry]) => {
      const cr = entry.contentRect;

      onResize(cr.height);
    });

    observer.observe(element);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [mainRef.current]);

  React.useEffect(() => {
    // we need to observe if some promo bar is added to the top bar to adjust the price box position
    const topBar = document.querySelector("#layout-top-bar") as HTMLElement;

    const mo = new MutationObserver(() => {
      onTopBarMutate();
    });

    mo.observe(topBar, { childList: true });

    return () => {
      mo.disconnect();
    };
  }, []);

  return (
    <Wrapper id="desktop-price-box">
      <GridContainer>
        <GridRow>
          <Grid.Column span={{ laptop: 4, desktop: 3 }} $offset={{ laptop: 9, desktop: 10 }}>
            <StickyBox id="desktop-price-box__sticky-box">{children}</StickyBox>
          </Grid.Column>
        </GridRow>
      </GridContainer>
    </Wrapper>
  );
};

import React from "react";
import { useTranslations } from "next-intl";

import { Audiobook } from "resources/AudiotekaApi";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { NewIcon } from "ui/NewIcon";

import { Tag } from "./details/tag";
import css from "./collections.module.scss";

export const Collections = ({ audiobook }: { audiobook: Audiobook }) => {
  const t = useTranslations();

  const collections = audiobook._embedded["app:contained-in"];

  if (collections.length === 0) {
    return null;
  }

  return (
    <div>
      <p className={css.description}>{t("product.collection_description")}:</p>
      <ul className={css.list}>
        {collections.map((cycle) => (
          <li key={cycle.id}>
            <Tag {...deeplinkTranslator(cycle.deeplink, cycle.slug)}>
              <NewIcon icon="cycle" width="12" height="12" />
              {cycle.name}
            </Tag>
          </li>
        ))}
      </ul>
    </div>
  );
};

import React from "react";
import classNames from "classnames";
import { useTranslations } from "next-intl";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";
import { Text } from "ui/text";
import { ButtonPrimitive } from "ui/button/button-primitive";

import { getPrices } from "../../../../utils";
import { AddToCartButton } from "../buttons";
import { BoxContentSubtitle } from "../components";
import css from "./club-member-mobile.module.scss";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
};

export function ClubMemberMobile({ audiobook, licenses }: Props) {
  const price = getPrices(audiobook, licenses, true).withCurrency;
  const t = useTranslations();

  const [isVisible, setIsVisible] = React.useState(false);

  if (!price.base) {
    return null;
  }

  const isClubPrice = price.final === price.club;

  return (
    <div className={css.clubMemberMobile}>
      <ButtonPrimitive
        className={css.header}
        onClick={() => {
          setIsVisible((_isVisible) => !_isVisible);
        }}
      >
        <Text type="subtitleNormal" className={css.title}>
          {t("product.price_box.club_member.title")}
        </Text>
        <NewIcon icon={isVisible ? "chevron-up" : "chevron-down"} width="24" height="24" />
      </ButtonPrimitive>
      <div className={classNames(css.contentWrapper, { [css.contentWrapper__visible]: isVisible })}>
        <div>
          <div className={css.content}>
            <Text type="heading5" className={classNames(css.price, { [css.price__club]: isClubPrice })}>
              {price.final !== price.base && price.final !== price.club ? (
                <Text className={css.oldPrice} as="span" type="bodyNormal">
                  {price.base}
                </Text>
              ) : null}
              {price.final}
            </Text>
            <Text type="bodySmall" className={css.description}>
              {t("product.price_box.club_member.buy_abook_available_in_club")}
              {isClubPrice ? ` ${t("product.price_box.club_member.buy_in_club_price")}` : "."}
            </Text>
            <AddToCartButton audiobook={audiobook} licenses={licenses} outlined />
            {isClubPrice && (
              <BoxContentSubtitle className={css.noClubPrice}>
                {t("product.price_box.price_without_club", { price: price.base })}
              </BoxContentSubtitle>
            )}
            {price.lowest && !isClubPrice && (
              <BoxContentSubtitle className={css.lowestPrice}>
                {t("product.lowest_price", { price: price.lowest.replace(/ /g, "\xa0") })}
              </BoxContentSubtitle>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import dynamic from "next/dynamic";

import SlideBar from "modules/DesignSystem/components/SlideBar";
import { Audiobook, ProductReview } from "resources/AudiotekaApi";

import { Loader } from "../loader/loader";

const FormContent = dynamic(() => import("./form-content"), {
  ssr: false,
  loading: () => <Loader />,
});

interface Props extends Pick<React.ComponentProps<typeof SlideBar>, "onClose" | "open"> {
  onReview(review: ProductReview): void;
  audiobook: Audiobook;
  review: ProductReview | null;
}

export function ReviewForm({ onClose, onReview, open, audiobook, review: existingReview }: Props) {
  return (
    <SlideBar onClose={onClose} open={open}>
      {open && <FormContent audiobook={audiobook} open={open} review={existingReview} onReview={onReview} />}
    </SlideBar>
  );
}

import React from "react";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";

import { getIsOnShelf, getIsShared, getPrices } from "../../utils";
import { FreeProduct } from "./boxes/free-product";
import { ProductOnShelf } from "./boxes/product-on-shelf/product-on-shelf";
import { ClubMember, ClubOnlyProduct } from "./boxes/club";
import { ProductToBuy } from "./boxes/product-to-buy/product-to-buy";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  isClubMember?: boolean;
};

export const PriceBox = ({ audiobook, licenses, isClubMember }: Props) => {
  const { is_available_in_subscription: clubListen } = audiobook;

  const price = getPrices(audiobook, licenses, isClubMember).withCurrency;

  const isShared = getIsShared(licenses);
  const isOnShelf = getIsOnShelf(licenses);
  const canBuy = !!price.base;

  if (audiobook.is_free) {
    // Free product
    return <FreeProduct audiobook={audiobook} licenses={licenses} />;
  }
  if (isOnShelf) {
    // Already on shelf
    return (
      <ProductOnShelf
        audiobook={audiobook}
        canDownload={licenses?.oneoff.is_downloadable || false}
        isShared={isShared}
      />
    );
  }
  if (clubListen) {
    // User can listen with club
    if (isClubMember) {
      return <ClubMember audiobook={audiobook} licenses={licenses} price={price} />;
    }
    // Club only. Cannot buy
    if (!canBuy) {
      return <ClubOnlyProduct />;
    }
  }

  if (canBuy) {
    return <ProductToBuy audiobook={audiobook} licenses={licenses} price={price} clubListen={clubListen} />;
  }

  return null;
};
